<template>
  <v-container>
    <div class="d-flex align-center mb-4">
      <h1 class="text-h5">
        <span class="work-sans">Solicitações de deposito</span>
      </h1>

      <v-spacer />

      <v-chip class="mr-2" color="surface" link @click="handleOrder()">
        <span>
          {{ pagination.order === 0 ? "Mais Recentes" : "Mais Antigo" }}
        </span>

        <v-icon right>
          {{ pagination.order === 0 ? "mdi-chevron-down" : "mdi-chevron-up" }}
        </v-icon>
      </v-chip>

      <v-chip color="surface" link>
        <span> Exportar </span>
        <v-icon right>mdi-download</v-icon>
      </v-chip>
    </div>

    <loader-content v-if="loading" />

    <section v-else>
      <FilterInput
        class="mb-4"
        :dataFilters="require('@/assets/filters/deposits.json')"
      />

      <v-card class="shadow rounded-lg mb-6" color="backgorund">
        <v-data-table
          v-model="selectedItems"
          class="transparent"
          item-key="depositoID"
          :headers="_headers"
          :items="deposits"
          hide-default-footer
          disable-pagination
          disable-sort
          show-select
        >
          <!-- user -->
          <template v-slot:[`item.name`]="{ item }">
            <router-link :to="`/user/${item.userID || item.id}`">
              <v-chip color="primary">
                <v-icon small left>mdi-account</v-icon>

                <span class="cut">{{ item.name }}</span>
              </v-chip>
            </router-link>
          </template>

          <!-- value -->
          <template v-slot:[`item.valor`]="{ item }">
            {{ currencyMask(item.valor) }}
            {{ item.moeda }}
          </template>

          <!-- valorDepositoReais -->
          <template v-slot:[`item.valorDepositoReais`]="{ item }">
            R$ {{ currencyMask(item.valorDepositoReais) }}
          </template>

          <!-- status -->
          <template v-slot:[`item.status`]="{ item }">
            <v-chip :color="handleStatus(item.status).color" label small dark>
              {{ handleStatus(item.status).label }}
            </v-chip>
          </template>

          <!-- actions -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn class="rounded-lg" icon @click="handleManage(item)">
              <v-icon>mdi-open-in-app</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>

      <!-- pagination -->
      <v-pagination
        v-model="pagination.page"
        color="primary"
        :length="pagination.pageCount"
        :total-visible="10"
        @input="getData()"
      />
    </section>

    <ManageDeposit ref="manageDeposit" @success="getData()" />
  </v-container>
</template>

<script>
import { request } from "@/services";
import { currencyMask, handlePagination } from "@/utils";
import FilterInput from "@/components/filters/FilterInput";
import ManageDeposit from "@/components/deposits/ManageDeposit.vue";
const moment = require("moment");

export default {
  data() {
    return {
      loading: true,
      selectedItems: [],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
        order: 0,
      },
      deposits: [],
    };
  },

  components: {
    FilterInput,
    ManageDeposit,
  },

  beforeMount() {
    this.getData();
  },

  watch: {
    ["$route.query"](value) {
      this.handleQuery(value);
    },
  },

  computed: {
    _headers() {
      return [
        { text: "ID", value: "depositoID" },
        { text: "Cliente", value: "name" },
        { text: "Valor", value: "valor" },
        { text: "Método", value: "infos" },
        { text: "Produto", value: "nomeContrato" },
        { text: "Data", value: "data" },
        { text: "Status", value: "status", align: "center" },
        { text: "Ações", value: "actions", align: "center" },
      ];
    },

    _fileName() {
      return `earthguard_deposits_export_${moment().format("YYYY-MM-DD")}.csv`;
    },
  },

  methods: {
    async getData(filter = {}) {
      try {
        const payload = {
          method: "listDeposits",
          page: this.pagination.page - 1,
          order: this.pagination.order,
          ...filter,
        };

        await request(payload).then(async (res) => {
          this.deposits = res.depositos;
          this.pagination.pageCount = handlePagination(res.total);
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    handleManage(data) {
      this.$refs.manageDeposit.open(data);
    },

    handleQuery(value) {
      this.loading = true;
      this.pagination.page = 1;

      const query = {
        FiltroUserID: value.id || undefined,
        nameFilter: value.name || undefined,
        emailFilter: value.email || undefined,
        statusFilter: value.status || undefined,
        dataInicial: value.period ? value.period.split(",")[0] : undefined,
        dataFinal: value.period ? value.period.split(",")[1] : undefined,
      };

      this.getData(query);
    },

    handleOrder() {
      this.pagination.order = this.pagination.order === 1 ? 0 : 1;
      this.getData();
    },

    handleStatus(value) {
      switch (String(value)) {
        case "0":
          return {
            label: "Aguardando",
            color: "amber",
          };
        case "1":
          return {
            label: "Aprovado",
            color: "green",
          };
        case "-1":
          return {
            label: "Recusado",
            color: "red",
          };
        default: {
          return {
            label: "Carregando",
            color: "grey",
          };
        }
      }
    },

    currencyMask,
  },
};
</script>

<style></style>
